import React from 'react';
import { useNavigate } from 'react-router-dom';
const Footer = () => {
    return (
        <footer>
            <section>&copy; 2023 AC-RAD | All Rights Reserved |</section>
        </footer>
    );
};

export default Footer;

import JohnDoe from "../images/John Doe.png";

export const PrincipalInvestigators = [
  {
    name: "Alán Aspuru-Guzik",
    image: JohnDoe,
  },
  {
    name: "Florian Shkurti",
    image: JohnDoe,
  },
  {
    name: "Animesh Garg",
    image: JohnDoe,
  },
];

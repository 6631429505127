import React from "react";
const Error = () => {
  return (
    <main>
      <p>Oops, that's an issue</p>
      <p>The link you chose is not working. We're working on it!</p>
    </main>
  );
};

export default Error;
